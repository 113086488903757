/**
 * @see {@link https://www.hl7.org/fhir/datatypes.html#Coding}
 * @see {import('./Coding.php')}
 */
export type Coding = {
  system: string;
  code: string;
  display: string;
};

/**
 * The coding identifier as single string for use as key value.
 *
 * Useful for `v-for` where the list has unique codings, like select options.
 */
export function codingKey(coding: Coding) {
  return `${coding.system}::${coding.code}`;
}
