<template>
  <div
    :id="`editExaminationModal-${examination.caseNo}`"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="editExamination"
    aria-hidden="true"
  >
    <div class="modal-dialog edit-examination-modal-dialog" role="document">
      <form
        class="edit-examination-container modal-content"
        @submit.prevent="onSaveValues"
      >
        <div class="modal-header">
          <h5 class="modal-title">
            {{ examination.caseNo }}
          </h5>
          <button
            type="button"
            :disabled="loading"
            class="close"
            aria-label="Close"
            @click="onCancelModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <clip-loader v-if="loading" />
        <div v-else class="modal-body edit-examination-body">
          <div v-for="field in fields" :key="field.id" class="input-item">
            <field
              :examination-id="examination.id"
              :title="field.title"
              :data="field.data"
              :type="field.type"
              :form-value="getFormValue(values[field.title], trans)"
              :extra-options="field.extraOptions"
              :value-options="field.valueOptions"
              :disabled="field.disabled"
              :required="field.required"
              @field-unmount="handleFieldUnmount(field)"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            :disabled="loading"
            class="btn btn-secondary"
            @click="onCancelModal"
          >
            {{ trans("general.modalCancelButton") }}
          </button>
          <button :disabled="!enableSave" type="submit" class="btn btn-primary">
            {{ trans("general.formSaveLabel") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { getFormValue } from "../../../../../helpers/product";
import {
  getFieldConditionsByOperator,
  getFieldConditionsByType,
  isDisabled,
  isRequired,
  isVisible,
} from "../../../../../lib/field-conditions";
import ClipLoader from "../../../../Utils/ClipLoader.vue";
import { editableFieldMap } from "../../fieldMap";

export default {
  components: { ClipLoader },
  props: {
    examination: Object,
    onSave: Function,
    onCancel: Function,
    productDefinition: Object,
    visible: Boolean,
    loading: Boolean,
    editType: String,
  },
  data() {
    return {
      values: this.getDefaultValues(this.examination, this.editType),
      enableSave: false,
    };
  },
  computed: {
    fields() {
      const { productDefinition, examination } = this;
      const editableFields = [];
      const caseDataGroup = productDefinition.field_groups.find(
        (group) => group.name === this.editType,
      );
      if (caseDataGroup && "frontend_fields" in caseDataGroup) {
        const fields = caseDataGroup.frontend_fields;
        fields.forEach((field) => {
          /**
           * Only use conditions of type "equals", they are the only ones relevant for the form
           */
          const filteredConditions = getFieldConditionsByOperator(
            field.field_conditions,
            "equals",
          );
          const visibleConditions = getFieldConditionsByType(
            filteredConditions,
            "visible",
          );
          const disabledConditions = getFieldConditionsByType(
            filteredConditions,
            "disabled",
          );
          const requiredConditions = getFieldConditionsByType(
            filteredConditions,
            "required",
          );

          const values = {
            ...examination,
            ...this.values,
          };

          const fieldConditions = {
            visible: isVisible(visibleConditions, values),
            disabled: isDisabled(disabledConditions, values),
            required: isRequired(requiredConditions, values),
          };

          if (field.visible && fieldConditions.visible) {
            const valueOptions = JSON.parse(
              JSON.stringify(JSON.parse(field.value_options)),
            );
            editableFields.push({
              id: field.id,
              title: field.name,
              data: this.getData(valueOptions),
              type: editableFieldMap[field.frontend_type ?? field.type],
              disabled: field.disabled || fieldConditions.disabled,
              required: field.required || fieldConditions.required,
              extraOptions: {
                onValueChange: this.onValueChange,
                key: field.name,
              },
              valueOptions,
            });
          }
        });
        return editableFields;
      }
    },
  },

  watch: {
    visible: function () {
      this.showModal();
    },
  },

  mounted: function () {
    $(`#editExaminationModal-${this.examination.caseNo}`).modal("show");

    $(`#editExaminationModal-${this.examination.caseNo}`).on(
      "hidden.bs.modal",
      () => this.onCancelModal(),
    );
  },

  methods: {
    handleFieldUnmount: function (field) {
      this.values[field.title] = undefined;
    },
    getFormValue,
    getDefaultValues: function (examination, editType) {
      const values = {};
      const caseDataGroup = this.productDefinition.field_groups.find(
        (group) => group.name === editType,
      );
      if (caseDataGroup && "frontend_fields" in caseDataGroup) {
        const fields = caseDataGroup.frontend_fields;
        fields.forEach((field) => {
          if (field.name in examination[editType]) {
            /**
             * Only get values for fields that are editable
             */
            if (editableFieldMap[field.frontend_type ?? field.type]) {
              values[field.name] = examination[editType][field.name];
            }
          }
        });
      }
      return values;
    },
    onCancelModal: function () {
      if (Object.keys(this.values).length > 0) {
        this.fields = [];
        this.values = {};
      }

      this.$emit("on-cancel");
      $(`#editExaminationModal-${this.examination.caseNo}`).modal("hide");
    },
    onSaveValues: function () {
      this.enableSave = false;
      this.$emit("on-save", this.values);
    },
    showModal: function () {
      if (this.visible) {
        this.mapData();
        $(`#editExaminationModal-${this.examination.caseNo}`).modal("show");
      } else {
        $(`#editExaminationModal-${this.examination.caseNo}`).modal("hide");
      }
    },

    getData: function (valueOptions) {
      if (valueOptions !== null) {
        const mapped = valueOptions.reduce((memo, current) => {
          if (current.range) {
            let i = current.range.start;
            while (i <= current.range.end) {
              const val = Math.round(i * 10) / 10;
              const value = current.postfix ? `${val}${current.postfix}` : val;
              const label = `${value}`;
              memo.push({ value, label });
              i += current.range.step;
            }
          } else {
            memo.push({
              value: current.data,
              label: this.trans(`examination.${current.title}`),
            });
          }
          return memo;
        }, []);
        return mapped;
      }
      return "";
    },
    onValueChange: function (input) {
      this.enableSave = true;
      const isBatch = Array.isArray(input.value);
      this.values[input.key] = isBatch
        ? JSON.stringify(input.value.map((val) => val.value))
        : input.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-examination-container {
  background-color: white;
}
.edit-examination-modal-dialog {
  max-width: 80%;
}
.edit-examination-body {
  display: grid;
  grid-template-columns: 50% 50%;
}
.input-item {
  margin: 0 1rem 1rem 1rem;
}
@media screen and (max-width: 700px) {
  .edit-examination-body {
    display: grid;
    grid-template-columns: 100%;
  }
}
</style>
