<template>
  <div class="vue-pan-zoom-item" :class="['vue-pan-zoom-item-' + instanceId]">
    <div class="vue-pan-zoom-scene">
      <div ref="scene">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @see https://github.com/thecodealer/vue-panzoom
 * Removed the dependency on 'vue-panzoom', because it was not compatible with Vue 3.
 * This is a copy of the original source code, with the following changes:
 * - panzoom is initialized with the 'scene' ref.
 * - global $panZoomInstance is replaced with a local variable.
 */

import panzoom from "panzoom";

export default {
  name: "PanZoom",
  props: {
    options: Object,
    selector: String,
    transformState: Object,
    isControlled: Boolean,
  },
  data() {
    return {
      suppressEvents: false,
      panZoomInstance: null,
      instanceId: null,
      defaultOptions: {
        autocenter: true,
        bounds: true,
        transformOrigin: {
          x: 0.5,
          y: 0.5,
        },
      },
    };
  },
  watch: {
    transformState(state) {
      if (this.isControlled) {
        this.suppressEvents = true;
        this.panZoomInstance.moveTo(state.x, state.y);
        this.panZoomInstance.zoomAbs(state.x, state.y, state.scale);
        this.suppressEvents = false;
      }
    },
  },
  created() {
    this.instanceId = this.generateRandomId(20);
  },
  mounted() {
    if (this.$refs.scene) {
      var _options = Object.assign({}, this.defaultOptions, this.options);
      this.panZoomInstance = panzoom(this.$refs.scene, _options);
      this.panZoomInstanceId = this.instanceId;
      this.attachEvents();
    }
  },
  methods: {
    generateRandomId(l) {
      l = l || 16;
      var chars =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var charsLength = chars.length;
      var a = [];

      for (var i = 0; i < l; i++) {
        a.push(chars.charAt(Math.floor(Math.random() * charsLength)));
      }
      return a.join("");
    },
    updateTransformState() {
      if (this.suppressEvents) return;
      const transform = this.panZoomInstance.getTransform();
      this.$emit("update:transform-state", {
        ...this.transformState,
        x: transform.x,
        y: transform.y,
        scale: transform.scale,
      });
    },
    attachEvents() {
      this.$emit("init", this.panZoomInstance, this.panZoomInstanceId);

      this.panZoomInstance.on("pan", this.updateTransformState);

      this.panZoomInstance.on("zoom", this.updateTransformState);
    },
    isPaused() {
      return this.panZoomInstance.isPaused();
    },
    pause() {
      this.panZoomInstance.pause();
    },
    resume() {
      this.panZoomInstance.resume();
    },
  },
};
</script>
