<template>
  <div>
    <div v-if="!!title">
      <strong>{{ title }}:</strong>
      <i v-if="required" class="far fa-asterisk"></i>
      <br />
    </div>
    <DerNumberInput
      :disabled="disabled"
      :model-value="value"
      :unit="unit"
      :required="required"
      :step="step"
      @update:model-value="onInput"
    />
  </div>
</template>

<script>
import _isNumber from "lodash/fp/isNumber";
import DerNumberInput from "../../../../Utils/DerNumberInput.vue";
import { parseValueAndUnitFromString } from "../../../../../helpers/formValidation";

export default {
  components: { DerNumberInput },
  props: {
    title: String,
    disabled: Boolean,
    required: Boolean,
    formValue: Object,
    extraOptions: {
      key: String,
      onValueChange: Function,
    },
    valueOptions: Array,
  },
  emits: ["field-unmount", "isValid"],
  data() {
    return {
      value: "",
    };
  },
  computed: {
    isValid() {
      return !this.required || _isNumber(this.value);
    },
    unit() {
      /**
       * valueOptions is an array of objects containing different configuration depending on the fieldtypes.
       * In this case, the first object contains a property postfix which we can use to display the unit of this value.
       */
      const options = this.valueOptions[0];
      if (options && options.postfix) {
        return options.postfix;
      }
    },
    step() {
      const options = this.valueOptions[0];
      if (options && options.step) {
        return options.step;
      }
    },
  },
  watch: {
    formValue: {
      handler: function (newValue) {
        if (newValue) {
          this.value = this.parseValueAndUnitFromString(newValue.value).val;
        }
      },
      immediate: true,
    },
    isValid: {
      handler: function (newValue) {
        this.$emit("isValid", newValue);
      },
      immediate: true,
    },
  },
  beforeUnmount: function () {
    this.$emit("field-unmount");
  },
  methods: {
    parseValueAndUnitFromString,
    onInput: function (value) {
      if (value) {
        this.value = value;
        this.extraOptions.onValueChange({
          key: this.extraOptions.key,
          value: this.unit ? `${value}${this.unit}` : value,
        });
      } else {
        this.value = "";
        this.extraOptions.onValueChange({
          key: this.extraOptions.key,
          value: null,
        });
      }
    },
  },
};
</script>
