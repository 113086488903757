import { Condition } from "../../../../Dermicus/Examinations/Condition";
import _pick from "lodash/fp/pick";

const CONDITION_UPDATE_ATTRS = ["onset_date", "abatement_date"] as const;

export type ConditionUpdateData = Pick<
  Condition,
  (typeof CONDITION_UPDATE_ATTRS)[number]
>;

export const conditionFormData = _pick(CONDITION_UPDATE_ATTRS);
