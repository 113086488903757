<template>
  <div>
    <div>
      <ul class="nav nav-tabs">
        <li
          v-for="(product, key) in sortedProducts"
          :key="key"
          class="nav-item"
          :class="'link-' + product.name"
        >
          <a
            :href="'#' + getProductName(product.id).replace(/\s/g, '')"
            data-toggle="tab"
            :class="{ active: key == 0 }"
            aria-expanded="false"
            class="nav-link"
            @click="setCurrentTab(product)"
          >
            <span>{{ product.display_name }}</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="tab-content">
      <div
        v-for="(product, key) in products"
        :id="getProductName(product.id).replace(/\s/g, '')"
        :key="key"
        class="tab-pane"
        :class="{ active: key == 0 }"
      >
        <mole v-if="product.id == 1" :patient="dataPatient"></mole>
        <photo v-if="product.id == 11" :patient="dataPatient"></photo>
        <wound v-if="product.id == 2" :patient="dataPatient"></wound>
        <patient-product
          v-if="dataPatient && patientProducts.includes(product.id)"
          :ref="`patientProduct${product.id}`"
          :patient="dataPatient"
          :product="product"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Mole from "./Products/Mole.vue";
import Wound from "./Products/Wound/Wound.vue";
import Photo from "./Products/Photo.vue";
import PatientProduct from "./Products/PatientProduct.vue";
import {
  getGenericPatientProductTypes,
  getProductNameByType,
} from "../../helpers/product";
export default {
  components: {
    Mole,
    Wound,
    Photo,
    PatientProduct,
  },
  props: {
    dataPatient: Object,
  },
  data: function () {
    return {
      loading: false,
      products: [],
      chatNotifications: null,
      patientProducts: getGenericPatientProductTypes(),
    };
  },
  computed: {
    sortedProducts: function () {
      let productsInDescendingOrder = this.products.sort(function (a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });

      return productsInDescendingOrder;
    },
  },
  watch: {
    dataPatient: {
      handler() {
        if (this.dataPatient) {
          this.$nextTick(() => {
            this.setCurrentTab(this.products[0]);
          });
        }
      },
      immediate: true,
    },
  },
  mounted: function () {
    this.setProducts();
  },
  methods: {
    setCurrentTab: function (product) {
      if (this.patientProducts.includes(product.id)) {
        const ref = `patientProduct${product.id}`;
        this.$refs[ref][0].loadData();
      }
    },
    setProducts: function () {
      const products = [];
      for (var productKey in this.$store.state.user.products) {
        let product = this.$store.state.user.products[productKey];
        if (this.dataPatient.productIds.includes(product.id)) {
          products.push(product);
        }
      }
      this.products = products;
    },
    getProductName: function (id) {
      return getProductNameByType(id);
    },
  },
};
</script>

<style lang="scss" scoped>
@use "/resources/assets/sass/abstracts/variables";

.nav.nav-tabs a.nav-link.active {
  color: variables.$dermicusBlue !important;
}
</style>
