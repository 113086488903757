<template>
  <div>
    <div class="row equal-height row-eq-height">
      <!-- Not in the style of generic products, but this is a hack to render the Wound view in the
     patient screen correctly. It puts the anamnesis in a column on the left, and the next sections
     in a column in the right. Ideally we would have a way to configure this in the definition,
     but that's not possible today. At the end, it puts the images at the bottom. Ugly,
     but workable for now. -->
      <div
        v-if="nonImageAndAnamnesisComponents && components.length !== 1"
        class="col-md-6"
      >
        <div v-if="components[0]">
          <component :is="components[0].element" v-bind="components[0].props" />
        </div>
      </div>
      <div v-if="nonImageAndAnamnesisComponents" class="col-md-6">
        <div
          v-for="(component, index) in nonImageAndAnamnesisComponents"
          :key="index"
          class="mb-4"
        >
          <component :is="component.element" v-bind="component.props" />
        </div>
      </div>
      <div v-if="caseImageComponent" class="col-md-12">
        <div v-if="caseImageComponent[0]">
          <component
            :is="caseImageComponent[0].element"
            v-bind="caseImageComponent[0].props"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapComponents } from "../../../Examinations/Examination/componentMapper";
import { provide } from "vue";
import { FIELD_CONSTANTS } from "../../../Examinations/Examination/fieldMap";

export default {
  props: {
    examination: Object,
    product: Object,
    viewOption: String,
  },
  setup() {
    provide("showPatientLevelReports", true);
  },
  computed: {
    components() {
      return this.product && this.examination
        ? mapComponents(
            this.product,
            JSON.parse(this.$store.state.user.permissions),
            this.examination,
            this.viewOption,
          )
        : [];
    },
    nonImageAndAnamnesisComponents() {
      return this.components
        .filter(
          (component) =>
            component.elementName !==
              FIELD_CONSTANTS["FIELD_GROUP_TYPE_PATIENT_IMAGES"] &&
            component.elementName !==
              FIELD_CONSTANTS["FIELD_GROUP_TYPE_IMAGES"],
        )
        .slice(1);
    },
    caseImageComponent() {
      return this.components.filter(
        (component) =>
          component.elementName ===
          FIELD_CONSTANTS["FIELD_GROUP_TYPE_PATIENT_IMAGES"],
      );
    },
  },
};
</script>
