<template>
  <div
    class="tw-relative tw-border-solid tw-border-[#dcdcdc] tw-rounded tw-border-[1px] tw-flex tw-items-center"
  >
    <input
      class="form-control !tw-border-none"
      type="number"
      :disabled="disabled"
      :value="modelValue"
      :required="required"
      :step="step"
      @input="handleInput"
    />
    <span
      v-if="unit"
      class="tw-p-2 tw-font-bold tw-text-gray-400 tw-whitespace-nowrap tw-border-0 tw-border-l tw-border-solid tw-border-[#dcdcdc]"
      >{{ unit }}</span
    >
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  modelValue?: string | number;
  disabled?: boolean;
  unit?: string;
  required?: boolean;
  step?: string;
}>();

const emit = defineEmits<{
  (event: "update:modelValue", value: string): void;
}>();

function handleInput(event: Event) {
  const target = event.target as HTMLInputElement;
  if (target) {
    emit("update:modelValue", target.value);
  }
}
</script>
