<template>
  <div v-if="products.length > 0" class="row">
    <div v-for="(product, index) in products" :key="index" class="col-md-6">
      <div class="panel panel-default hover-effect">
        <div class="panel-heading p-0">
          <div
            :class="[{ grayscale: !product.active }, 'widget-img']"
            :style="{
              background: `url('/images/products/${product.image}') center right no-repeat`,
            }"
          >
            {{ product.display_name }}
          </div>
        </div>
        <div class="panel-body">
          <div class="row col-md-12">
            <div class="col-md-12">
              <DerToggleWithLabel
                v-model="product.active"
                :label="trans('users.notificationActive')"
              />
            </div>
          </div>

          <horizontal-line
            v-if="product.active"
            class="mb-3"
            :text="trans('users.notificationHeadline')"
          />

          <div v-if="product.active" class="row col-md-12 tw-gap-y-8">
            <div
              v-for="notification in product.notifications"
              :key="notification.id"
              class="col-4"
            >
              <DerToggleWithLabel
                v-model="notification.active"
                :label="trans(notification.trans)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HorizontalLine from "../../../Utils/HorizontalLine.vue";
import DerToggleWithLabel from "../../../../design-system/DerToggleWithLabel.vue";

export default {
  components: {
    HorizontalLine,
    DerToggleWithLabel,
  },
  props: {
    products: {},
    user: {},
  },
};
</script>
