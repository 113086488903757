<template>
  <User
    :user="user"
    :expand-user-info="expandUserInfo"
    @expand-user-info="expandUserInfo = !expandUserInfo"
  />

  <tr v-if="expandUserInfo">
    <td colspan="6" class="hiddenRow">
      <user-options
        :user-data="user"
        :data-url="dataUrl"
        :center-data="centerData"
        :view-as-region-admin="viewAsRegionAdmin"
        @user-settings-changes="$emit('user-settings-changes', $event)"
      />
    </td>
  </tr>
</template>

<script setup lang="ts">
import { ref } from "vue";
import User from "./User.vue";
import UserOptions from "./UserOptions.vue";

const props = defineProps<{
  user: any;
  dataUrl: string;
  centerData: object;
  viewAsRegionAdmin: boolean;
}>();

const expandUserInfo = ref(false);
</script>

<style lang="scss" scoped>
tr td {
  border: 1px solid #ddd;
}
.hiddenRow {
  padding: 0 !important;
  background: #f5f5f5;
  position: relative;
  top: -5px;
  border-top: none !important;

  &:hover {
    color: #676a6c !important;
  }
}
</style>
