import { InjectionKey, ShallowRef } from "vue";

/**
 * When key is set, that field has validation errors. No empty messages!
 */
export type ValidationErrors = Record<string, string[]>;

export const VALIDATION_ERRORS_KEY: InjectionKey<
  Readonly<ShallowRef<ValidationErrors | undefined>>
> = Symbol();
