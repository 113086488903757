<template>
  <div class="tw-h-full tw-w-full tw-flex">
    <div class="tw-w-1/2 tw-h-full tw-relative">
      <Lightbox
        v-model:transform-state="transformState"
        :is-controlled="isControlled"
        :active-image="images[0]"
        @close-event="$emit('close-event')"
      />
    </div>
    <div
      class="tw-absolute tw-top-0 tw-h-full tw-left-1/2 -tw-translate-x-1/2 tw-w-1 tw-bg-gray-700 tw-z-10"
    />
    <div class="tw-w-1/2 tw-h-full tw-relative">
      <Lightbox
        v-model:transform-state="transformState"
        :is-controlled="isControlled"
        :active-image="images[1]"
        @close-event="$emit('close-event')"
      />
    </div>
    <div
      class="tw-absolute tw-bottom-4 tw-left-1/2 -tw-translate-x-1/2 tw-z-10 tw-bg-gray-700 tw-rounded-lg tw-p-4 tw-text-white"
    >
      <DerToggleWithLabel
        v-model="isControlled"
        class="-tw-order-1"
        :label="
          isControlled
            ? lang.get('general.locked')
            : lang.get('general.unlocked')
        "
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import Lightbox from "./Lightbox.vue";
import DerToggleWithLabel from "../../design-system/DerToggleWithLabel.vue";
import { lang } from "../../i18n";

const transformState = ref({
  rotation: 0,
  x: 0,
  y: 0,
  scale: 1,
});

const isControlled = ref(true);

const props = defineProps<{
  images: any;
}>();
</script>
