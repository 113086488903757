<template>
  <div class="tw-space-y-2 tw-relative tw-group">
    <div :style="{ transform: `rotate(${rotation}deg)` }">
      <slot />
    </div>
    <Button
      size="small"
      :variant="isEditing ? 'danger' : 'light'"
      :title="lang.get('general.edit')"
      class="tw-absolute tw-left-2 tw-bottom-2 group-hover:tw-opacity-100"
      :class="{ 'tw-opacity-0': !isEditing }"
      @click="handleEditToggle"
    >
      <i v-if="isEditing" class="fal fa-times"></i>
      <i v-else class="fal fa-pencil"></i>
    </Button>
    <div
      v-if="isEditing"
      class="tw-absolute tw-left-0 -tw-bottom-2 tw-translate-y-full tw-w-full tw-bg-white tw-shadow-md tw-border tw-border-solid tw-border-gray-200 tw-rounded-lg tw-p-1 tw-z-10 tw-flex tw-justify-between tw-items-center"
    >
      <div>
        <Button
          size="small"
          variant="link"
          class="!tw-px-1"
          title="Rotate ccw"
          @click="handleRotateCounterClockwise"
        >
          <RotateLeftIcon class="tw-w-4" />
        </Button>
        <Button
          size="small"
          variant="link"
          class="!tw-px-1"
          title="Rotate cw"
          @click="handleRotateClockwise"
        >
          <RotateRightIcon class="tw-w-4" />
        </Button>
      </div>
      <Button
        size="small"
        class="!tw-rounded-md"
        :is-loading="imageRotateMutation.isLoading.value"
        @click="handleSave"
      >
        {{ lang.get("general.formSaveLabel") }}
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import Button from "../../Layout/Button.vue";
import { ref } from "vue";
import RotateRightIcon from "../../Layout/Icons/RotateRightIcon.vue";
import RotateLeftIcon from "../../Layout/Icons/RotateLeftIcon.vue";
import { useExaminationImageRotate } from "../../../store/api/use-examination-resource";
import { lang } from "../../../i18n";

const props = defineProps<{
  dataImageId: number;
  dataExaminationId: number;
}>();

const emit = defineEmits(["edit-saved"]);

let isEditing = ref(false);
let rotation = ref(0);

function handleEditToggle() {
  isEditing.value = !isEditing.value;
  resetRotation();
}

const imageRotateMutation = useExaminationImageRotate();

function resetRotation() {
  rotation.value = 0;
}

function handleSave() {
  imageRotateMutation.mutate(
    {
      imageId: props.dataImageId,
      examinationId: props.dataExaminationId,
      rotation: rotation.value,
    },
    {
      onSuccess() {
        handleEditToggle();
        emit("edit-saved", {
          imageId: props.dataImageId,
          examinationId: props.dataExaminationId,
        });
      },
    },
  );
}

function handleRotateCounterClockwise() {
  rotation.value = (rotation.value - 90) % 360;
}

function handleRotateClockwise() {
  rotation.value = (rotation.value + 90) % 360;
}
</script>
