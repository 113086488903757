<template>
  <clip-loader v-show="loading" />
</template>

<script>
import { practitionerHttpService } from "../../app/practitioner-portal/store/practitioner-http-service";

export default {
  data() {
    return {
      loading: false,
    };
  },
  mounted: function () {
    practitionerHttpService
      .get(
        "/api/internal/auth/svensk-e-identitet/" + this.$route.params.customer,
      )
      .then((response) => {
        window.location = response.data.redirectUrl;
      });
  },
};
</script>
