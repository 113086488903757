<template>
  <form novalidate @submit.prevent="onSubmit" @reset.prevent="onReset">
    <slot :form-data="formData" />
  </form>
</template>
<script setup lang="ts" generic="T extends Record<string, unknown>">
import { computed, provide, readonly } from "vue";
import { VALIDATION_ERRORS_KEY } from "./form-handling";
import { UseMutationReturnType } from "@tanstack/vue-query";
import { reactify, useCloned } from "@vueuse/core";

/**
 * Form component, including data and validation logic.
 *
 * - Initial values are cloned and used for the form data.
 * - Initial values can be reset with a reset action.
 * - Given mutation will be triggered on the submit action.
 * - Given mutation will be reset on the reset action.
 * - Provides validation errors for components like `<DerFormGroup />`.
 * - Validation errors are extracted from the mutation error.
 */
defineOptions({});

const props = defineProps<{
  dataInitialValues: T;
  dataMutation: UseMutationReturnType<unknown, unknown, T, unknown>;
}>();

const emit = defineEmits<{
  (event: "reset"): void;
}>();

const { cloned: formData, sync: resetFormData } = useCloned(
  computed(() => props.dataInitialValues),
  { manual: true },
);

provide(
  VALIDATION_ERRORS_KEY,
  readonly(
    reactify((error) =>
      error?.response?.status === 422 ? error.response.data : undefined,
    )(props.dataMutation.error),
  ),
);

function onSubmit() {
  props.dataMutation.mutate(formData.value);
}

function onReset() {
  props.dataMutation.reset();
  resetFormData();
  emit("reset");
}
</script>
