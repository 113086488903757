<template>
  <Panel :title="lang.get('examination.ucr_user_connection')">
    <div class="form-group">
      <label for="ucr-id">{{ trans("examination.ucr_username") }}</label>
      <input
        id="ucr-id"
        v-model="ucrId"
        type="text"
        name="ucr-id"
        class="form-control"
      />
    </div>

    <div class="tw-flex tw-justify-end tw-gap-2">
      <Button
        :is-loading="ucrSync.isLoading.value"
        :disabled="ucrSyncDryrun.isLoading.value"
        @click="handleSync"
      >
        {{ trans("examination.ucr_sync_user_action") }}
      </Button>
      <Button
        variant="secondary"
        :is-loading="ucrSyncDryrun.isLoading.value"
        :disabled="ucrSync.isLoading.value"
        @click="handleDryRun"
      >
        {{ trans("examination.ucr_sync_user_action_dry_run") }}
      </Button>
    </div>
  </Panel>
  <DerDialog
    :visible="dryRunResults.length > 0"
    :header="lang.get('examination.ucr_sync_user_action_dry_run')"
    @update:visible="dryRunResults = []"
  >
    <ul class="tw-list-disc tw-pl-4 tw-space-y-4">
      <li v-for="result in dryRunResults" :key="result">
        {{ result }}
      </li>
    </ul>
    <div class="tw-flex tw-justify-end">
      <Button @click="dryRunResults = []">
        {{ lang.get("general.close") }}
      </Button>
    </div>
  </DerDialog>
</template>

<script setup lang="ts">
import { ref } from "vue";
import Panel from "../../../Layout/Panel.vue";
import { lang } from "../../../../i18n";
import {
  useAdminUserUcrSync,
  useAdminUserUcrSyncDryrun,
} from "../../../../store/api/use-admin-resource";
import notifications from "../../../Utils/notifications";
import DerDialog from "../../../../design-system/DerDialog.vue";
import Button from "../../../Layout/Button.vue";

const props = defineProps<{
  ucrId: string;
  userId: string;
}>();

const ucrSync = useAdminUserUcrSync();
const ucrSyncDryrun = useAdminUserUcrSyncDryrun();

const ucrId = ref(props.ucrId);
const dryRunResults = ref([]);

const emit = defineEmits(["ucr-synced"]);

function handleSync() {
  ucrSync.mutate(
    { ucrId: ucrId.value, userId: props.userId },
    {
      onSuccess(data) {
        if (data.success) {
          notifications.saveSuccessCustomText(data.message);
          emit("ucr-synced");
        } else {
          notifications.errorCustomText(data.message);
        }
      },
    },
  );
}

function handleDryRun() {
  ucrSyncDryrun.mutate(
    { ucrId: ucrId.value, userId: props.userId },
    {
      onSuccess(data) {
        if (data.success) {
          dryRunResults.value = JSON.parse(data.results);
        } else {
          notifications.errorCustomText(data.message);
        }
      },
    },
  );
}
</script>
