<template>
  <div>
    <div v-if="!!title">
      <strong>{{ title }}:</strong>
      <i v-if="required" class="far fa-asterisk" />
      <br />
    </div>
    <textarea
      class="form-control my-2"
      :value="value"
      :disabled="disabled"
      :required="required"
      @input="onInput($event.target.value)"
    />
  </div>
</template>

<script>
import _isString from "lodash/fp/isString";
/**
 * `FormTextfield` is a textarea component.
 */
export default {
  props: {
    title: String,
    disabled: Boolean,
    required: Boolean,
    formValue: String,
    extraOptions: {
      key: String,
      onValueChange: Function,
    },
  },
  emits: ["field-unmount", "isValid"],
  data() {
    return {
      value: "",
    };
  },
  computed: {
    isValid() {
      return (
        !this.required ||
        (_isString(this.value) && this.value.trim().length > 0)
      );
    },
  },
  watch: {
    formValue: {
      handler: function (newValue) {
        if (newValue) {
          this.value = newValue.value;
        }
      },
      immediate: true,
    },
    isValid: {
      handler: function (newValue) {
        this.$emit("isValid", newValue);
      },
      immediate: true,
    },
  },
  beforeUnmount: function () {
    this.$emit("field-unmount");
  },
  methods: {
    onInput: function (event) {
      this.value = event;
      this.extraOptions.onValueChange({
        key: this.extraOptions.key,
        value: event,
      });
    },
  },
};
</script>
