<template>
  <div v-if="Object.keys(images).length">
    <p>{{ examination.createdAt }}</p>
    <div
      class="patient-images-container justify-content-between align-items-end"
    >
      <div
        v-for="(image, key) in images"
        :key="key"
        class="patient-images-wrapper"
      >
        <label>{{ trans(`examination.image_${key}`) }}</label>
        <div class="image-container" @click="handleImageClick(image)">
          <encoded-image
            :image-id="image.id"
            style-class="img-fluid zoom lightbox-thumbnail"
            :case-id="examination.id"
            :uri="image.uri"
            @on-receive-encoded-image="onEncodedImageLoaded"
          />
        </div>
      </div>
      <div class="align-self-end">
        <button class="btn btn-primary" @click="visitCase">
          {{ trans("examination.visitCase") }}
        </button>
      </div>
    </div>

    <portal to="lightbox-container">
      <Lightbox
        v-if="activeImage"
        v-model:activeImage="activeImage"
        :images="lightboxImages"
        @close-event="handleClose"
      />
    </portal>

    <div class="mx-2 my-3 border-bottom" />
  </div>
</template>

<script>
import ConsultationImages from "../../AddOns/ConsultationImages.vue";
import EncodedImage from "../../AddOns/EncodedImage.vue";
import ImageGallery from "../../AddOns/ImageGallery.vue";
import Lightbox from "../../../Layout/Lightbox.vue";

export default {
  components: {
    ConsultationImages,
    ImageGallery,
    EncodedImage,
    Lightbox,
  },
  props: {
    fields: Array,
    examination: Object,
  },
  data() {
    return {
      activeImage: false,
      encodedImages: {},
    };
  },
  computed: {
    images() {
      return this.examination.images || {};
    },
    lightboxImages() {
      return Object.values(this.images).map((image) => ({
        id: image.id,
        url: this.encodedImages[image.id] || image.uri,
      }));
    },
  },
  methods: {
    handleImageClick(image) {
      this.activeImage = this.activeImage
        ? false
        : this.lightboxImages.find((img) => parseInt(img.id) === image.id);
    },
    handleClose() {
      this.activeImage = false;
    },
    onEncodedImageLoaded: function (event) {
      this.encodedImages = {
        ...this.encodedImages,
        [event.imageId]: event.uri,
      };
    },
    visitCase: function () {
      this.$router.push({
        path: `/consultations/${this.examination.product.url}/${this.examination.id}/edit`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-images-container {
  display: flex;
  flex-direction: row;
  margin: 10px 0 10px 0;
}
.image-container {
  max-height: 200px;
  max-width: 200px;
  margin-right: 10px;
}

@media (max-width: 415px) {
  .patient-images-container {
    display: block;

    .btn.btn-primary {
      margin-top: 10px;
      width: 100%;
    }

    .patient-images-wrapper {
      margin-top: 10px;

      .image-container {
        width: 100%;
        height: auto;
        max-height: initial;
        max-width: initial;
      }
    }
  }
}
</style>
