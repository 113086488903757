<template>
  <div class="form-group" :class="{ 'has-error': errors?.[dataName] }">
    <label class="tw-m-0" :for="inputId">{{ dataLabel }}</label>
    <br />
    <slot :input-id="inputId" />
    <template v-if="errors?.[dataName]">
      <span v-for="errorMessage in errors?.[dataName]" class="form-text">
        <strong>{{ errorMessage }}</strong>
      </span>
    </template>
  </div>
</template>
<script setup lang="ts">
import { inject } from "vue";
import { VALIDATION_ERRORS_KEY } from "./form-handling";
import { v4 as uuidV4 } from "uuid";

defineProps<{ dataName: string; dataLabel: string }>();

const inputId = uuidV4();

const errors = inject(VALIDATION_ERRORS_KEY, undefined);
</script>
