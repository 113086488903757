<template>
  <img v-if="encoded" :src="encodedUri" :class="styleClass" :width="width" />
  <skeleton-loader v-else-if="!encoded && imageId" />
</template>

<script>
import getEncodedImage from "../../Utils/LazyLoadingImg/GetEncodedImage";
import SkeletonLoader from "../../Utils/SkeletonLoader.vue";
import axios from "axios";

export default {
  components: {
    SkeletonLoader,
  },
  props: {
    imageId: Number,
    styleClass: String,
    caseId: Number,
    uri: String,
    width: String,
  },
  data: function () {
    return {
      encodedUri: "",
      encoded: false,
    };
  },
  mounted: function () {
    this.getImage();
  },
  methods: {
    getImage: async function () {
      if (this.imageId) {
        this.encoded = false;
        const uri = await getEncodedImage(
          this.caseId,
          this.imageId,
          this.createCancelToken(),
        );
        if (uri) {
          this.encodedUri = uri;
          this.encoded = true;
          this.$emit("on-receive-encoded-image", {
            imageId: this.imageId,
            uri: uri,
          });
        }
      } else {
        this.encodedUri = this.uri;
        this.encoded = true;
      }
    },
    createCancelToken: function () {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      this.$store.commit("addCancelToken", { token: source });
      return source.token;
    },
  },
};
</script>
