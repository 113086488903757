<template>
  <PatientExaminationHeader
    :examination-collection="collection"
    :is-expanded="isExpanded"
    :is-open="isOpen"
    @toggle-open="isOpen = !isOpen"
  />

  <tr v-if="isOpen" class="hiddenRow">
    <td :colspan="nrOfColumns" class="hiddenRowContent">
      <PatientExaminationContainer
        class="pt-3"
        :product-definition="productDefinition"
        :examination-collection="collection"
      />
    </td>
  </tr>
</template>

<script setup lang="ts">
import { ref } from "vue";
import PatientExaminationHeader from "./PatientExaminationHeader.vue";
import PatientExaminationContainer from "./PatientExaminationContainer.vue";

const props = defineProps<{
  collection: any;
  productDefinition: any;
  isExpanded: boolean;
  nrOfColumns: number;
}>();

const isOpen = ref(false);
</script>

<style lang="scss" scoped>
table {
  border-collapse: separate;
  border-spacing: 0 0.3rem;

  tr {
    td {
      border-bottom: 2px solid #f5f5f5 !important;

      &:first-of-type {
        border-left: 2px solid #f5f5f5;
      }

      &:last-of-type {
        border-right: 2px solid #f5f5f5;
      }
    }
  }
}
.hiddenRow {
  background: #ffff;
  color: #676a6c !important;

  .hiddenRowContent .collapse.show {
    border-top: 2px solid #dee2e6 !important;
  }

  .hiddenRowContent {
    padding: 0 !important;
    position: relative;
    top: -5px;
    border-top: none;
  }

  &:hover {
    background-color: #ffff;
  }
}
</style>
