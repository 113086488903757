<template>
  <div id="take_care_files" class="tab-pane">
    <div id="custom-search-input">
      <form class="input-group col-md-12" @submit.prevent="search">
        <input
          id="takeCareSearchInput"
          v-model="searchFields.case_no"
          type="text"
          class="form-control input-lg"
          :placeholder="trans('forms.searchForCaseNumber')"
          :title="trans('general.search')"
        />
        <span class="input-group-btn">
          <button
            v-if="isSearching"
            class="btn"
            type="button"
            @click.stop="clearSearch"
          >
            <i class="fal fa-times" />
          </button>
          <button class="btn" type="button" @click.stop="search">
            <i class="far fa-search" />
          </button>
        </span>
      </form>
    </div>
    <div class="mb-3 advanced-search">
      <div class="row mb-3">
        <div class="col-md-3">
          <label for="center">{{
            trans("evaluation.tableColumnCenter")
          }}</label>
          <select2
            id="center"
            v-model="searchFields.center_id"
            :data-placeholder="trans('forms.chooseCenterPlaceholder')"
            name="center"
          >
            <option disabled />
            <option
              v-for="(center, index) in centers"
              :key="index"
              :value="center.id"
            >
              {{ center.name }}
            </option>
          </select2>
        </div>

        <div class="col-md-3">
          <label for="requisition_id">Requisition id</label>
          <input
            id="requisition_id"
            v-model="searchFields.requisition_id"
            type="text"
            class="form-control input-lg"
            name="requisition_id"
          />
        </div>

        <div class="col-md-3">
          <label>{{ trans("evaluation.tableColumnCreatedAtFrom") }}</label>
          <custom-datepicker
            :date="searchFields.dateFrom"
            :placeholder="trans('evaluation.tableColumnCreatedAtFrom')"
            @on-date-change="changeDates('dateFrom', $event)"
          />
        </div>
        <div class="col-md-3">
          <label>{{ trans("evaluation.tableColumnCreatedAtTo") }}</label>
          <custom-datepicker
            :date="searchFields.dateUntil"
            :placeholder="trans('evaluation.tableColumnCreatedAtTo')"
            @on-date-change="changeDates('dateUntil', $event)"
          />
        </div>
      </div>
    </div>

    <clip-loader v-if="loading" />

    <div class="table-responsive">
      <table class="table table-hover">
        <thead class="sortable-table-header">
          <tr>
            <th>Center</th>
            <th>Case created by</th>
            <th>Type</th>
            <th>Examination</th>
            <th>Requisition id</th>
            <th>Comment by</th>
            <th>Received</th>
            <th>Handled at</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-for="(log, index) in take_care_files.logs" :key="index">
            <td>{{ log.center }}</td>
            <td>{{ log.examination_user }}</td>
            <td>{{ log.type }}</td>
            <td>{{ log.examination }}</td>
            <td>{{ log.requisition_id }}</td>
            <td>{{ log.comment_user }}</td>
            <td>{{ log.is_received }}</td>
            <td>{{ log.file_handled_at }}</td>
            <td>
              <a
                data-toggle="modal"
                data-target="#takeCareDetail"
                class="btn"
                @click="getTakeCareLogDetail(log.id)"
                >Details</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row-fluid pull-right">
      <DerPaginate
        v-model="paginate.current_page"
        :page-count="paginate.last_page"
        @change="getTakeCareLogs"
      />
    </div>

    <div
      id="takeCareDetail"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <table class="table">
              <tr>
                <th>Id</th>
                <td>{{ takeCareLogDetail.id }}</td>
              </tr>

              <tr>
                <th>Center</th>
                <td>{{ takeCareLogDetail.center }}</td>
              </tr>

              <tr>
                <th>Type</th>
                <td>{{ takeCareLogDetail.type }}</td>
              </tr>

              <tr>
                <th>Direction</th>
                <td>{{ takeCareLogDetail.direction }}</td>
              </tr>

              <tr>
                <th>Examination no</th>
                <td>{{ takeCareLogDetail.examination }}</td>
              </tr>

              <tr>
                <th>Requisition id</th>
                <td>{{ takeCareLogDetail.requisition_id }}</td>
              </tr>

              <tr>
                <th>Examination created by</th>
                <td>{{ takeCareLogDetail.examination_user }}</td>
              </tr>

              <tr>
                <th>Created at</th>
                <td>{{ takeCareLogDetail.referral_created_at }}</td>
              </tr>

              <tr>
                <th>Consultation answered by</th>
                <td>{{ takeCareLogDetail.comment_user }}</td>
              </tr>

              <tr>
                <th>File name</th>
                <td>{{ takeCareLogDetail.filename }}</td>
              </tr>

              <tr>
                <th>File handled at</th>
                <td>{{ takeCareLogDetail.file_handled_at }}</td>
              </tr>

              <tr>
                <th>Message id</th>
                <td>{{ takeCareLogDetail.message_id }}</td>
              </tr>

              <tr>
                <th>Photographer</th>
                <td>{{ takeCareLogDetail.photographed_user }}</td>
              </tr>

              <tr>
                <th>Photographed at</th>
                <td>{{ takeCareLogDetail.photographed_at }}</td>
              </tr>

              <tr>
                <th>XML file</th>
                <td />
              </tr>
            </table>

            <pre lang="xml">
                  {{ takeCareLogDetail.xml }}
                </pre
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DerPaginate from "../../../design-system/DerPaginate.vue";
import { queryStringBuilder } from "../../../helpers/misc";
import xmlbeautify from "xml-beautifier";

export default {
  filters: {
    pretty: function (value) {
      return JSON.stringify(JSON.parse(value), null, 2);
    },
  },
  components: { DerPaginate },
  data() {
    return {
      loading: true,
      paginate: {
        current_page: 0,
        total: 0,
        last_page: 0,
      },
      take_care_files: {
        logs: [],
      },
      isSearching: false,
      centers: [],
      searchFields: {
        center_id: "",
        case_no: null,
        requisition_id: null,
        dateFrom: "",
        dateUntil: "",
      },
      takeCareLogDetail: [],
    };
  },
  created: function () {
    this.getCenters();
    this.getTakeCareLogs();
  },
  methods: {
    getTakeCareLogs(page) {
      this.loading = true;
      if (typeof page === "undefined") {
        page = 1;
      }
      axios
        .get("/api/internal/admin/logs/take-care?page=" + page)
        .then((data) => {
          var collection = data.data;
          this.paginate = collection.paginate;
          this.take_care_files.logs = collection.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    search: function () {
      this.loading = true;
      this.isSearching = true;
      axios
        .get(
          "/api/internal/admin/logs/take-care?" +
            queryStringBuilder(this.searchFields),
        )
        .then((data) => {
          var collection = data.data;
          this.take_care_files.logs = collection.data;
          this.paginate = collection.paginate;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    clearSearch: function () {
      Object.assign(
        this.$data.searchFields,
        this.$options.data.call(this).searchFields,
      );
      this.isSearching = false;
      this.getTakeCareLogs(1);
    },
    getTakeCareLogDetail: function (id) {
      this.loading = true;
      axios
        .get("/api/internal/admin/logs/take-care-detail/" + id)
        .then((data) => {
          const collection = data.data.data;
          this.takeCareLogDetail = collection;
          try {
            let formattedXml = xmlbeautify(collection.xml);
            this.takeCareLogDetail.xml = formattedXml;
          } catch (error) {
            console.error(error);
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    changeDates: function (target, date) {
      if (target === "dateFrom") {
        this.searchFields.dateFrom = date;
      } else {
        this.searchFields.dateUntil = date;
      }
    },
    getCenters: function () {
      axios
        .get("/api/internal/admin/all-centers")
        .then((data) => {
          this.centers = data.data;
        })
        .catch((error) => {
          console.log("Get centers error: ", error);
        });
    },
  },
};
</script>
