<template>
  <div class="advanced-search">
    <div class="row">
      <div v-if="searchOptions.products.length > 1" class="col-md-4">
        <label>{{ trans("evaluation.tableColumnProduct") }}</label>
        <v-select
          v-model="searchFields.products"
          name="products"
          :options="searchOptions.products"
          label="display_name"
          :reduce="(product) => product.id"
          :placeholder="trans('examination.externalResourceSelectPlaceholder')"
        />
      </div>

      <div v-if="searchOptions.centers.length > 1" class="col-md-4 mb-2">
        <label>{{ trans("evaluation.tableColumnCenter") }}</label>
        <v-select
          v-model="searchFields.center"
          name="center"
          :options="searchOptions.centers"
          label="text"
          :reduce="(center) => center.id"
          :placeholder="trans('examination.externalResourceSelectPlaceholder')"
        />
      </div>

      <div class="col-md-4 mb-2">
        <label>{{ trans("evaluation.tableColumnUserTypes") }}</label>
        <v-select
          v-model="searchFields.userType"
          name="userActivity"
          :options="searchOptions.userTypes"
          label="text"
          :reduce="(userType) => userType.value"
          :placeholder="trans('examination.externalResourceSelectPlaceholder')"
        />
      </div>

      <div class="col-md-4 mb-2">
        <label>{{ trans("evaluation.tableColumnUsers") }}</label>
        <v-select
          v-model="searchFields.user"
          name="users"
          :options="searchOptions.users"
          label="text"
          :reduce="(user) => user.id"
          :placeholder="trans('examination.externalResourceSelectPlaceholder')"
        />
      </div>

      <div class="col-md-4 mb-2">
        <label>{{ trans("evaluation.tableColumnStatus") }}</label>
        <v-select
          v-model="searchFields.status"
          name="status"
          :options="searchOptions.statuses"
          label="text"
          :reduce="(status) => status.id"
          :placeholder="trans('examination.externalResourceSelectPlaceholder')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    searchOptions: {
      products: Array,
      centers: Array,
      userTypes: Array,
      users: Array,
      statuses: Array,
    },
    searchFields: Object,
  },
};
</script>

<style></style>
