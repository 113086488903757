<template>
  <DerForm
    :data-initial-values="initialValues"
    :data-mutation="dataMutation"
    #="{ formData }"
    @reset="emit('reset')"
  >
    <DerDataGridLayout>
      <DerFormGroup
        data-name="onset_date"
        :data-label="lang.get('model.Condition.onset_date.label')"
      >
        <Datepicker
          :date="formData.onset_date"
          @on-date-change="formData.onset_date = $event === '' ? null : $event"
        />
      </DerFormGroup>
      <DerFormGroup
        data-name="abatement_date"
        :data-label="lang.get('model.Condition.abatement_date.label')"
      >
        <Datepicker
          :date="formData.abatement_date"
          @on-date-change="
            formData.abatement_date = $event === '' ? null : $event
          "
        />
      </DerFormGroup>
    </DerDataGridLayout>
    <div class="text-right">
      <Button type="reset" class="tw-mr-2" variant="secondary">
        {{ lang.get("general.modalCancelButton") }}
      </Button>
      <Button>
        {{ lang.get("general.formSaveLabel") }}
      </Button>
    </div>
  </DerForm>
</template>
<script setup lang="ts">
import { Condition } from "../../../../app/Dermicus/Examinations/Condition";
import { lang } from "../i18n";
import Button from "./Layout/Button.vue";
import DerFormGroup from "../design-system/DerFormGroup.vue";
import DerForm from "../design-system/DerForm.vue";
import { UseMutationReturnType } from "@tanstack/vue-query";
import DerDataGridLayout from "../design-system/DerDataGridLayout.vue";
import Datepicker from "./Utils/Datepicker.vue";
import {
  conditionFormData,
  ConditionUpdateData,
} from "../../../../app/Http/Controllers/Api/Internal/ExaminationsController";
import { computed } from "vue";

const props = defineProps<{
  dataCondition: Condition;
  dataMutation: UseMutationReturnType<
    unknown,
    unknown,
    ConditionUpdateData,
    unknown
  >;
}>();

const emit = defineEmits<{
  (event: "reset"): void;
}>();

const initialValues = computed(() => conditionFormData(props.dataCondition));
</script>
