import { translator } from "./misc";

export const PRODUCT_PERMISSIONS = {
  //  PRODUCT COMPONENTS
  COMPARE_IMAGES: "compare_images",
  USE_CONSENSUS: "use_consensus",
  USE_PATIENT_CONSENSUS: "use_patient_consensus",
  HAS_PATIENT_WOUND_LIST: "has_patient_wound_list",
  USE_DIAGNOSIS_SE: "use_diagnosis_se",

  // PRINT TYPES
  PRINT_CASE_REFERRAL: "print_case_referral",
  PRINT_CASE_IMAGES: "print_case_images",
  PRINT_CASE_SUMMARY: "print_case_summary",
  PRINT_CASE_REPORT_WITHOUT_IMAGES: "print_case_report_without_images",

  // ACTIONS
  PRINT_CASE: "print_case",
  CLOSE_CASES: "close_cases",
  PRIORITISE_CASES: "prioritise_cases",
  FLAG_CASES: "flag_cases",
  BOOKMARK_CASES: "bookmark_cases",
  MARK_AS_TEST_PATIENT: "mark_as_test_patient",
  MANAGE_MAIN_EXAMINATIONS: "manage_main_examinations",
  EDIT_EXAMINATION_VALUES: "edit_examination_values",
  EDIT_MEDICAL_HISTORY: "edit_medical_history",

  // DOCUMENTATION ANSWERS
  HAS_NOTES: "has_notes",
  HAS_PATHOLOGY_ANSWERS: "has_pathology_answers",
  HAS_CONSULTATION_ANSWERS: "has_consultation_answers",
  HAS_REFERRAL_ANSWERS: "has_referral_answers",

  // AI FEATURES
  USE_AI_DIAGNOSIS: "use_ai_diagnosis",
  USE_AI_PRIORITIZATION: "use_ai_prioritization",
};

export const PRODUCTS = {
  MOLE: "mole",
  WOUND: "wound",
  WOUND_LEGACY: "woundLegacy",
  PHOTO: "photo",
  PHOTO_UK: "photoUK",
  MOLE_UK: "moleUk",
  MOLE_EDUCATIONS: "moleEducations",
  TUMOUR_WOUND: "tumourWound",
  REGION_SKANE_MOLE: "regionSkaneMole",
  INTERNATIONAL: "international",
  WOUND_UK: "woundUK",
  WOUND_LITE: "woundLite",
};

export const genericPatientProducts = [
  PRODUCTS.TUMOUR_WOUND,
  PRODUCTS.WOUND,
  PRODUCTS.WOUND_UK,
  PRODUCTS.PHOTO,
  PRODUCTS.PHOTO_UK,
  PRODUCTS.REGION_SKANE_MOLE,
];

export const PRODUCT_ACTION_NAMES = {
  PRIORITISE_CASES: "prioritise_cases",
  FLAG_CASES: "flag_cases",
  BOOKMARK_CASES: "bookmark_cases",
  MARK_AS_TEST_PATIENT: "mark_as_test_patient",
  EDIT_EXAMINATION: "edit_examination_values",
  PRINT_CASE: "print_case",
  CLOSE_CASE: "close_cases",
  USE_UCR: "use_ucr",
  UCR_PROMOTION: "ucr_promotion",
  EDIT_MEDICAL_HISTORY: "edit_medical_history",
};

export const PRODUCT_ACTIONS = [
  {
    name: PRODUCT_ACTION_NAMES.PRINT_CASE,
    title: translator("examination.tabLabelPrint"),
    enabled: false,
    hasBoolValue: false,
  },
  {
    name: PRODUCT_ACTION_NAMES.PRIORITISE_CASES,
    title: translator("general.actionPrioritizeButton"),
    removeTitle: translator("general.actionPrioritizeButtonRemove"),
    enabled: false,
    hasBoolValue: true,
    submitable: true,
    key: "isPrioritized",
  },
  {
    name: PRODUCT_ACTION_NAMES.FLAG_CASES,
    title: translator("general.actionFlagButton"),
    removeTitle: translator("general.actionFlagButtonRemove"),
    enabled: false,
    hasBoolValue: true,
    submitable: true,
    key: "isFlagged",
  },
  {
    name: PRODUCT_ACTION_NAMES.BOOKMARK_CASES,
    title: translator("general.actionBookmarkButton"),
    removeTitle: translator("general.actionBookmarkButtonRemove"),
    enabled: false,
    hasBoolValue: true,
    submitable: true,
    key: "isBookmarked",
  },
  {
    name: PRODUCT_ACTION_NAMES.MARK_AS_TEST_PATIENT,
    title: translator("general.actionTestPatientButton"),
    removeTitle: translator("general.actionTestPatientButtonRemove"),
    enabled: false,
    hasBoolValue: true,
    submitable: true,
    key: "isTestPatient",
  },
  {
    name: PRODUCT_ACTION_NAMES.EDIT_EXAMINATION,
    title: translator("examination.diagnosReplyEditButton"),
    enabled: false,
    hasBoolValue: false,
  },
  {
    name: PRODUCT_ACTION_NAMES.CLOSE_CASE,
    title: translator("examination.caseStatusCloseCaseLabel"),
  },
  {
    name: PRODUCT_ACTION_NAMES.UCR_PROMOTION,
    title: translator("general.actionSubmitUCR"),
    enabled: false,
    hasBoolValue: false,
  },
  {
    name: PRODUCT_ACTION_NAMES.USE_UCR,
    title: translator("general.actionSubmitUCR"),
    enabled: false,
    hasBoolValue: false,
  },
  {
    name: PRODUCT_ACTION_NAMES.EDIT_MEDICAL_HISTORY,
    title: translator("general.actionEditMedicalHistory"),
    enabled: false,
    hasBoolValue: false,
  },
];

export const STORE_CONSTANTS = {
  LOAD_PATIENT_CASES: "loadPatientCases",
  RELOAD_EXAMINATION: "reloadExamination",
  LOAD_STUDY_WORKFLOW: "loadStudyWorkflow",
};

export const DOCUMENTATION_TYPE_NAMES = {
  CONSULTATION_ANSWER: "consultation_answer",
  NOTES: "notes",
  PATHOLOGY_ANSWER: "pathology_answer",
  REFERRAL_ANSWER: "referral_answer",
};

// Will be adjusted after Tomour wound definition adjustments
export const DOCUMENTATION_PERMISSION_TYPES = {
  has_consultation_answers: DOCUMENTATION_TYPE_NAMES.CONSULTATION_ANSWER,
  has_notes: DOCUMENTATION_TYPE_NAMES.NOTES,
  has_pathology_answers: DOCUMENTATION_TYPE_NAMES.PATHOLOGY_ANSWER,
  has_referral_answers: DOCUMENTATION_TYPE_NAMES.REFERRAL_ANSWER,
};

export const DOCUMENTATION_TYPES = {
  [DOCUMENTATION_TYPE_NAMES.PATHOLOGY_ANSWER]: 2,
  [DOCUMENTATION_TYPE_NAMES.NOTES]: 1,
  [DOCUMENTATION_TYPE_NAMES.REFERRAL_ANSWER]: 4,
  [DOCUMENTATION_TYPE_NAMES.CONSULTATION_ANSWER]: 3,
};

export const DOCUMENTATION_ANSWERS = [
  {
    name: DOCUMENTATION_TYPE_NAMES.CONSULTATION_ANSWER,
    title: translator("examination.consultCommentTypeConsultantResponse"),
    note: translator("forms.warningAboutAnswerSavedInPatientRecords"),
    type: DOCUMENTATION_TYPES[DOCUMENTATION_TYPE_NAMES.CONSULTATION_ANSWER],
  },
  {
    name: DOCUMENTATION_TYPE_NAMES.NOTES,
    title: translator("examination.consultCommentTypeNotes"),
    note: translator("forms.noNotificationNoteAnswer"),
    type: DOCUMENTATION_TYPES[DOCUMENTATION_TYPE_NAMES.NOTES],
  },
  {
    name: DOCUMENTATION_TYPE_NAMES.REFERRAL_ANSWER,
    title: translator("examination.consultCommentTypeReferralAnswer"),
    note: translator("forms.warningAboutReferralAnswerSavedInPatientRecords"),
    type: DOCUMENTATION_TYPES[DOCUMENTATION_TYPE_NAMES.REFERRAL_ANSWER],
  },
  {
    name: DOCUMENTATION_TYPE_NAMES.PATHOLOGY_ANSWER,
    title: translator("examination.consultCommentTypePatholgyAnswers"),
    note: translator("forms.noNotificationPathologyAnswer"),
    type: DOCUMENTATION_TYPES[DOCUMENTATION_TYPE_NAMES.PATHOLOGY_ANSWER],
  },
];
