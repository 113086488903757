<template>
  <div>
    <div class="row image-container">
      <div
        v-for="(image, index) in images"
        :key="index"
        class="col-md-6 mt-3 mb-4"
      >
        <div class="d-flex justify-content-between align-items-baseline">
          <label>
            {{ image.label }}
            {{
              "isPolarized" in image
                ? image.isPolarized
                  ? `(${trans("examination.aiPolarized")})`
                  : `(${trans("examination.aiNonPolarized")})`
                : ""
            }}
          </label>
          <button
            v-if="image.id"
            class="btn btn-link"
            style="color: #676a6c"
            @click="downloadImage(image)"
          >
            <i class="fad fa-download" />
          </button>
        </div>

        <component
          :is="
            hasBoundingBox(dataImageMetadata?.[image.filename])
              ? 'bounding-box-image'
              : 'img'
          "
          v-if="image.encodedUri && image.id"
          class="img-fluid zoom lightbox-thumbnail"
          :src="image.encodedUri"
          :data-metadata="dataImageMetadata?.[image.filename]"
          @click="handleImageClick(image)"
        />

        <skeleton-loader v-else-if="!image.encodedUri && image.id" />
      </div>
    </div>
    <slot />
    <portal to="lightbox-container">
      <Lightbox
        v-if="activeImage"
        v-model:activeImage="activeImage"
        :images="lightboxImages"
        @close-event="handleClose"
      />
    </portal>
  </div>
</template>

<script>
import SkeletonLoader from "../../Utils/SkeletonLoader.vue";
import getEncodedImage from "../../Utils/LazyLoadingImg/GetEncodedImage";
import Lightbox from "../../Layout/Lightbox.vue";
import axios from "axios";
import BoundingBoxImage from "../../Layout/BoundingBoxImage.vue";
import { hasBoundingBox } from "../../../../../../app/Dermicus/Studies/StudyResult";

export default {
  components: {
    SkeletonLoader,
    Lightbox,
    BoundingBoxImage,
  },
  props: {
    images: Object,
    dataImageMetadata: Object,
    caseId: Number,
  },
  data() {
    return {
      activeImage: false,
    };
  },
  computed: {
    lightboxImages() {
      return Object.values(this.images)
        .map((image) => ({
          id: image.id,
          url: image.encodedUri,
          dataMetadata: this.dataImageMetadata?.[image.filename],
        }))
        .filter((image) => image.id);
    },
  },
  mounted: function () {
    this.encodeImages(this.images);
  },
  methods: {
    hasBoundingBox,
    handleImageClick(image) {
      this.activeImage = this.activeImage
        ? false
        : this.lightboxImages.find((img) => parseInt(img.id) === image.id);
    },
    handleClose() {
      this.activeImage = false;
    },
    encodeImages: async function () {
      Object.keys(this.images).forEach(async (el) => {
        this.images[el] = {
          ...this.images[el],
          encodedUri: await this.getImage(this.images[el]),
        };
      });
    },
    getImage: async function (img) {
      if (img.id) {
        return await getEncodedImage(
          this.caseId,
          img.id,
          this.createCancelToken(),
        );
      }
    },
    createCancelToken: function () {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      this.$store.commit("addCancelToken", { token: source });
      return source.token;
    },
    downloadImage: function (img) {
      const imageBase64 = img.encodedUri;
      const a = document.createElement("a");
      a.href = imageBase64;
      a.download = img.filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
};
</script>

<style lang="scss" scoped>
.image-container {
  min-height: 200px;
}
</style>
