<template>
  <FieldPanel
    title="view.product.conditionGroup.title"
    data-has-edit-mode
    @activate-edit-mode="usageMode = 'capture'"
  >
    <UseStoreResource
      class="panel-body"
      :data-resource="conditionResource"
      #="{ data: condition }"
    >
      <ConditionDisplay
        v-if="usageMode === 'display'"
        :data-condition="condition"
      />
      <ConditionForm
        v-else
        :data-condition="condition"
        :data-mutation="conditionUpdate"
        @reset="usageMode = 'display'"
      />
    </UseStoreResource>
  </FieldPanel>
</template>
<script setup lang="ts">
import { useMutation, useQuery } from "@tanstack/vue-query";
import {
  examinationConditionGet,
  examinationConditionUpdate,
} from "../../../../store/core/examinations";
import UseStoreResource from "../../../../design-system/UseStoreResource.vue";
import FieldPanel from "./components/FieldPanel.vue";
import { computed, shallowRef } from "vue";
import ConditionForm from "../../../ConditionForm.vue";
import ConditionDisplay from "../../../ConditionDisplay.vue";
import { userErrorMessage } from "../../../../mixins/UserErrorMessage.vue";
import { reactify } from "@vueuse/core";

/**
 * @see {import('../componentMapper').mapComponents}
 */
const props = defineProps<{ examination: { id: number } }>();

const usageMode = shallowRef("display");

const examinationId = computed(() => props.examination.id);

const conditionResource = useQuery(
  reactify(examinationConditionGet)(examinationId),
);

const conditionUpdate = useMutation({
  mutationFn: reactify(examinationConditionUpdate)(examinationId),
  onError: userErrorMessage,
  onSuccess() {
    usageMode.value = "display";
  },
});
</script>
