<template>
  <div>
    <div v-if="userHasNoProducts" class="overlay">
      <div class="overlay-content">{{ userHasNoProductsMessage }}</div>
    </div>

    <patients-advanced-search
      :patient-search-options="patientSearchOptions"
      :search-fields="searchFields"
      @get-patient-search-results="getResults"
      @clear-search="clearSearch"
    ></patients-advanced-search>

    <div id="patients" class="mt-4">
      <div v-if="loading" class="overlay">
        <clip-loader></clip-loader>
      </div>
      <div class="table-responsive">
        <table class="table table-hover table-actions-bar interactive-table">
          <thead class="sortable-table-header">
            <tr>
              <th
                v-if="$can('prioritise_cases')"
                class="consultations-list-icon"
              ></th>
              <th
                v-if="$can('prioritise_cases')"
                class="consultations-list-icon"
              ></th>
              <th
                v-if="$can('prioritise_cases')"
                class="consultations-list-icon"
              ></th>
              <th>{{ trans("evaluation.tableColumnStatus") }}</th>
              <th
                v-if="!$store.state.user.hidePatientFields"
                class="sort-by"
                :class="sortBySelection === 'ssn' ? 'table-active' : ''"
                @click="sortBy('ssn', $event)"
              >
                {{ trans("patient.ssn") }}
                <i class="fas fa-caret-down"></i>
              </th>
              <th
                v-if="!$store.state.user.hidePatientFields"
                class="sort-by"
                :class="sortBySelection === 'firstname' ? 'table-active' : ''"
                @click="sortBy('firstname', $event)"
              >
                {{ trans("forms.firstname") }}
                <i class="fas fa-caret-down"></i>
              </th>
              <th
                v-if="!$store.state.user.hidePatientFields"
                class="sort-by"
                :class="sortBySelection === 'lastname' ? 'table-active' : ''"
                @click="sortBy('lastname', $event)"
              >
                {{ trans("forms.lastname") }}
                <i class="fas fa-caret-down"></i>
              </th>
              <th
                class="sort-by"
                :class="sortBySelection === 'center_id' ? 'table-active' : ''"
                @click="sortBy('center_id', $event)"
              >
                {{ trans("evaluation.tableColumnCenter") }}
                <i class="fas fa-caret-down"></i>
              </th>
              <th
                class="sort-by"
                :class="
                  sortBySelection === 'photographed_at' ? 'table-active' : ''
                "
                @click="sortBy('photographed_at', $event)"
              >
                {{ trans("patient.tableHeaderLatestPhotoDocumentation") }}
                <i class="fas fa-caret-down"></i>
              </th>
              <th>
                {{ trans("patient.tableHeaderPatientProducts") }}
              </th>
              <th>{{ trans("patient.tableHeaderChatMessages") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(patient, index) in patients"
              :key="index"
              :class="{ last: isLastOpened(patient.id) }"
              @click="openPatient(patient)"
            >
              <td
                v-if="$can('prioritise_cases')"
                class="consultations-list-icon"
              >
                <bookmark-icon v-if="patient.isBookmarked" />
              </td>
              <td
                v-if="$can('prioritise_cases')"
                class="consultations-list-icon"
              >
                <flag-icon v-if="patient.isFlagged" />
              </td>
              <td
                v-if="$can('prioritise_cases')"
                class="consultations-list-icon"
              >
                <priority-icon v-if="patient.isPrioritized" />
              </td>
              <td>
                <case-status
                  :examination="patient"
                  :hide-label="true"
                ></case-status>
              </td>
              <td v-if="!$store.state.user.hidePatientFields">
                {{ patient.ssn }}
              </td>
              <td
                v-if="!$store.state.user.hidePatientFields"
                v-dompurify-html:[DOMPURIFY_CONFIG_NAME.fullName]="
                  patient.firstname
                "
              ></td>
              <td v-if="!$store.state.user.hidePatientFields">
                {{ patient.lastname }}
              </td>
              <td>{{ patient.centerName }}</td>
              <td>{{ patient.latestPhotographedAt }}</td>
              <td>{{ patient.products }}</td>
              <td>
                <consensus-messages-count
                  :all-messages="patient.totalMessages"
                  :unread-messages="patient.unreadMessages"
                ></consensus-messages-count>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row-fluid float-right">
        <DerPaginate
          v-model="paginate.current_page"
          :page-count="paginate.last_page"
          @change="getResults"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PriorityIcon from "../Utils/ActionIcons/PriorityIcon.vue";
import FlagIcon from "../Utils/ActionIcons/FlagIcon.vue";
import BookmarkIcon from "../Utils/ActionIcons/BookmarkIcon.vue";
import TestIcon from "../Utils/ActionIcons/TestIcon.vue";
import CaseStatus from "../Examinations/Examination/ExaminationComponents/components/CaseStatus.vue";
import ConsensusMessagesCount from "../Utils/ConsensusMessagesCount.vue";
import PatientsAdvancedSearch from "./PatientsList/PatientsAdvancedSearch.vue";
import DerPaginate from "../../design-system/DerPaginate.vue";
import { DOMPURIFY_CONFIG_NAME } from "../../dompurify";
import { queryStringBuilder } from "../../helpers/misc";

export default {
  components: {
    PriorityIcon,
    BookmarkIcon,
    TestIcon,
    FlagIcon,
    CaseStatus,
    ConsensusMessagesCount,
    PatientsAdvancedSearch,
    DerPaginate,
  },
  data() {
    return {
      DOMPURIFY_CONFIG_NAME,
      loading: false,
      patients: [],
      paginate: {
        current_page: 0,
        total: 0,
        last_page: 0,
      },
      searchFields: {
        query: "",
        page: 1,

        sort: {
          field: "photographedAt",
          orderBy: "DESC",
        },

        mainPatientsFilter: "",

        // extra patient filters
        bookmarked: "",
        prioritized: "",
        flagged: "",
        hasUnreadMessages: "",
        hasUnreadPrivateMessages: "",
        hasUnreadDocumentation: "",

        // advanced search search fields
        products: null,
        center: null,
        userType: null,
        user: null,
        status: null,
      },
      sortBySelection: "photographed_at",
      patientSearchOptions: {
        patientFilters: [],
        extraPatientFilters: [],
      },
      userHasNoProducts: false,
      userHasNoProductsMessage: this.trans(
        "errorMessages.userHasNoActiveProducts",
      ),
    };
  },
  created: function () {
    this.setQueryFields();
    this.getResults();
  },
  methods: {
    getResults: function (page) {
      this.loading = true;

      this.searchFields.page = page ? page : this.searchFields.page;

      localStorage.setItem(
        "last_patients_query_uri",
        JSON.stringify(this.searchFields),
      );

      axios
        .get("/api/internal/patients?" + queryStringBuilder(this.searchFields))
        .then((data) => {
          this.loading = false;
          let collection = data.data;

          if (collection.error) {
            this.userHasNoProducts = true;
          } else {
            this.patients = collection.data;
            this.searchOptions = collection.searchOptions;
            this.paginate = collection.paginate;

            this.patientSearchOptions = this.searchOptions;
            this.patientSearchOptions.products = this.filterProducts();
          }
        })
        .catch((error) => {
          this.loading = false;
          this.userErrorMessage(error);
        });

      // get query from local storage query
      this.setQueryFields();
    },
    clearSearch: function () {
      this.clearQuerySearch();
      this.getResults(1);
    },
    clearQuerySearch: function () {
      localStorage.removeItem("last_patients_query_uri");

      // Reset searchFields to defualt values
      Object.assign(
        this.$data.searchFields,
        this.$options.data.call(this).searchFields,
      );

      this.resetAllSortBy();
    },
    resetAllSortBy: function () {
      $("th.sort-by")
        .find("i.fas.fa-caret-up")
        .removeClass("fa-caret-up")
        .addClass("fa-caret-down");
    },
    isLastOpened: function (patientId) {
      return localStorage.getItem("last_visited_patient_id") == patientId;
    },
    openPatient: function (patient) {
      this.$router.push({
        name: "PatientOverview",
        params: { id: patient.id },
      });
    },
    sortBy: function (field, event) {
      this.loading = true;
      this.sortBySelection = field;

      // Reset all to bottom
      if (field !== this.searchFields.sort.field) {
        $("i.fas.fa-caret-up")
          .removeClass("fa-caret-up")
          .addClass("fa-caret-down");

        this.searchFields.sort.orderBy = "DESC";
      }

      this.searchFields.sort.field = field;

      if (this.searchFields.sort.orderBy == "ASC") {
        this.searchFields.sort.orderBy = "DESC";
        $(event.currentTarget)
          .find("i.fas.fa-caret-up")
          .removeClass("fa-caret-up")
          .addClass("fa-caret-down");
      } else {
        this.searchFields.sort.orderBy = "ASC";
        $(event.currentTarget)
          .find("i.fas.fa-caret-down")
          .removeClass("fa-caret-down")
          .addClass("fa-caret-up");
      }
      localStorage.setItem(
        "last_patients_query_uri",
        JSON.stringify(this.searchFields),
      );

      this.getResults(this.searchFields.page);
    },
    setQueryFields: function () {
      // sets values from local storage
      const parsedQueryUri = localStorage.getItem("last_patients_query_uri");

      if (parsedQueryUri) {
        this.searchFields = JSON.parse(parsedQueryUri);
      }
    },
    filterProducts: function () {
      return this.$store.state.user.products;
    },
  },
};
</script>

<style scoped>
.consultations-list-icon {
  padding: 0px 2px;
}

.overlay-content {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin: 20px 0px;
  text-align: center;
  font-weight: bold;
}
</style>
