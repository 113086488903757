import _get from "lodash/fp/get";

export type FieldCondition = {
  type: FieldConditionType;
  operator: FieldConditionOperator;
  condition_field: string;
  value: any;
};

type FieldConditionType = "visible" | "disabled" | "required";

type FieldConditionOperator = "equals" | "is_set";

type Values = Record<string, any>;

export function getFieldConditionsByType(
  fieldConditions: FieldCondition[],
  type: FieldConditionType,
) {
  return fieldConditions.filter((condition) => condition.type === type);
}

export function getFieldConditionsByOperator(
  fieldConditions: FieldCondition[],
  operator: FieldConditionOperator,
) {
  return fieldConditions.filter((condition) => condition.operator === operator);
}

export function isVisible(
  conditions: FieldCondition[],
  values: Values,
): boolean {
  return conditions.every((condition) => checkCondition(condition, values));
}

export function isDisabled(
  conditions: FieldCondition[],
  values: Values,
): boolean {
  return conditions.some((condition) => checkCondition(condition, values));
}

export function isRequired(
  conditions: FieldCondition[],
  values: Values,
): boolean {
  return conditions.some((condition) => checkCondition(condition, values));
}

function checkCondition(condition: FieldCondition, values: Values): boolean {
  const value = _get(condition.condition_field, values);
  return operator[condition.operator](value, condition.value);
}

export const operator = {
  equals: (value: any, conditionValue: any): boolean => {
    if (Array.isArray(value)) {
      return value.includes(conditionValue) && conditionValue;
    }
    if (typeof value === "object" && value !== null) {
      return conditionValue in value && value[conditionValue];
    }
    return value == conditionValue;
  },
  is_set: (value: any, conditionValue: any): boolean => {
    if (conditionValue === "false" || conditionValue === false)
      return value === null || value === undefined || value === "";
    return value !== null && value !== undefined && value !== "";
  },
};
