import { UseMutationOptions } from "@tanstack/vue-query";
import { practitionerHttpService } from "../../app/practitioner-portal/store/practitioner-http-service";
import { userErrorMessage } from "../../mixins/UserErrorMessage.vue";

export function adminUserSyncUcr(): UseMutationOptions<
  any,
  any,
  {
    userId: string;
    ucrId: string;
  },
  unknown
> {
  return {
    mutationFn: ({ userId, ucrId }) =>
      practitionerHttpService
        .post(`/api/internal/admin/users/${userId}/ucr/${ucrId}`)
        .then((response) => response.data),
    onError: userErrorMessage,
  };
}

export function adminUserSyncUcrDryrun(): UseMutationOptions<
  any,
  any,
  {
    userId: string;
    ucrId: string;
  },
  unknown
> {
  return {
    mutationFn: ({ userId, ucrId }) =>
      practitionerHttpService
        .post(`/api/internal/admin/users/${userId}/ucr-dry-run/${ucrId}`)
        .then((response) => response.data),
    onError: userErrorMessage,
  };
}
