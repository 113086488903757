<template>
  <div id="system" class="tab-pane active">
    <clip-loader v-if="loading" />

    <div class="mb-3 advanced-search">
      <form @submit.prevent="getQuicksilvaLogs(1)">
        <div class="row mb-3">
          <div class="col-md-3">
            <label for="subject">Case number</label>
            <input
              id="case"
              v-model="searchFields.case"
              type="text"
              class="form-control input-lg"
              name="subject"
            />
          </div>

          <div class="col-md-3">
            <label for="username">Username</label>
            <input
              id="username"
              v-model="searchFields.username"
              type="text"
              class="form-control input-lg"
              name="username"
            />
          </div>

          <div class="col-md-3">
            <label>{{ trans("evaluation.tableColumnCreatedAtFrom") }}</label>
            <custom-datepicker
              :date="searchFields.dateFrom"
              :placeholder="trans('evaluation.tableColumnCreatedAtFrom')"
              @on-date-change="changeDates('dateFrom', $event)"
            />
          </div>

          <div class="col-md-3">
            <label>{{ trans("evaluation.tableColumnCreatedAtTo") }}</label>
            <custom-datepicker
              :date="searchFields.dateUntil"
              :placeholder="trans('evaluation.tableColumnCreatedAtTo')"
              @on-date-change="changeDates('dateUntil', $event)"
            />
          </div>

          <div class="col-md-6 pt-2 mt-3 text-right">
            <button
              v-if="isSearching"
              class="btn btn-danger mr-2"
              type="button"
              @click.stop="clearSearch"
            >
              <span>{{ trans("general.clearSearch") }}</span>
              <i class="fal fa-times" />
            </button>
            <button
              class="btn btn-primary"
              type="submit"
              @click.stop="getEmailLogs(1)"
            >
              <span>{{ trans("general.search") }}</span>
              <i class="far fa-search" />
            </button>
          </div>
        </div>
      </form>
    </div>

    <div class="table-responsive">
      <table class="table table-hover">
        <thead class="sortable-table-header">
          <tr>
            <th>Batch</th>
            <th>Description</th>
            <th>Method</th>
            <th>User</th>
            <th>Examination</th>
            <th>Created at</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(log, index) in quicksilva.logs" :key="index">
            <td>
              <a href="#" @click="updatePageWithBatch(log.batch_uuid)">
                {{ log.batch_uuid }}</a
              >
            </td>
            <td>{{ log.description }}</td>
            <td>{{ log.event }}</td>
            <td>{{ log.causer_name }} ({{ log.causer_id }})</td>
            <td>{{ log.case_no }}</td>
            <td>{{ log.created_at }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row-fluid float-right">
      <DerPaginate
        v-model="quicksilva.paginate.current_page"
        :page-count="quicksilva.paginate.last_page"
        @change="getQuicksilvaLogs"
      />
    </div>
  </div>
</template>

<script>
import DerPaginate from "../../../design-system/DerPaginate.vue";
import { practitionerHttpService } from "../../../app/practitioner-portal/store/practitioner-http-service";
import { queryStringBuilder } from "../../../helpers/misc";

export default {
  components: { DerPaginate },
  data() {
    return {
      loading: false,
      quicksilva: {
        paginate: {
          current_page: 0,
          total: 0,
          last_page: 0,
        },
        logs: [],
      },
      isSearching: false,
      searchFields: {
        case: "",
        username: "",
        dateFrom: "",
        dateUntil: "",
        page: 1,
      },
      lastBatchUuid: "",
    };
  },
  created: function () {
    this.getQuicksilvaLogs();
  },
  methods: {
    getQuicksilvaLogs(page, batchUuid = "") {
      if (self.lastBatchUuid) {
        batchUuid = ""; // clicking the same batchUuid twice clears the filter
      }

      self.lastBatchUuid = batchUuid;

      this.loading = true;
      if (typeof page === "undefined") {
        page = 1;
      }

      practitionerHttpService
        .get(
          "/api/internal/admin/logs/quicksilva?batch_uuid=" +
            self.lastBatchUuid +
            "&page=" +
            page +
            "&" +
            queryStringBuilder(this.searchFields),
        )
        .then((data) => {
          var collection = data.data;
          this.quicksilva.paginate = collection.paginate;
          this.quicksilva.logs = collection.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    changeDates: function (target, date) {
      if (target === "dateFrom") {
        this.searchFields.dateFrom = date;
      } else {
        this.searchFields.dateUntil = date;
      }
    },
    updatePageWithBatch(batchUuid) {
      this.getQuicksilvaLogs(undefined, batchUuid);
    },
  },
};
</script>
