import { UseMutationOptions } from "@tanstack/vue-query";
import { practitionerHttpService } from "../../app/practitioner-portal/store/practitioner-http-service";
import { ExaminationAttachment } from "../../../../../app/Dermicus/Examinations/ExaminationAttachment";
import { saveAs } from "file-saver";
import contentDisposition from "content-disposition";
import { StudyConsent } from "../../../../../app/Dermicus/PatientPortal/StudyConsent";
import { notify } from "@kyvg/vue3-notification";
import { lang } from "../../i18n";
import { userErrorMessage } from "../../mixins/UserErrorMessage.vue";
import { queryClient } from "../api";
import { queryStringBuilder } from "../../helpers/misc";
import { Condition } from "../../../../../app/Dermicus/Examinations/Condition";
import _tap from "lodash/fp/tap";
import { ConditionUpdateData } from "../../../../../app/Http/Controllers/Api/Internal/ExaminationsController";

export function examinationsGet(searchFields: Record<string, string | number>) {
  return practitionerHttpService
    .get<{
      data: [{ id: string; editUrl: string }];
      paginate: {
        current_page: number;
        last_page: number;
      };
    }>(`/api/internal/examinations?${queryStringBuilder(searchFields)}`)
    .then((response) => response.data);
}

export function examinationConditionGet(id: number) {
  return {
    queryKey: ["examinations", id, "condition"],
    queryFn: () =>
      practitionerHttpService
        .get<Condition>(`/api/internal/examinations/${id}/condition`)
        .then((response) => response.data),
  };
}

export function examinationConditionUpdate(id: number) {
  return (data: ConditionUpdateData) =>
    practitionerHttpService
      .put<Condition>(`/api/internal/examinations/${id}/condition`, data)
      .then(
        _tap((response) =>
          queryClient.setQueryData(
            ["examinations", id, "condition"],
            response.data,
          ),
        ),
      );
}

export function examinationAttachmentsGet(id: number) {
  return {
    queryKey: ["examinationAttachments", id],
    queryFn: () =>
      practitionerHttpService
        .get<
          ExaminationAttachment[]
        >(`/api/internal/examinations/${id}/attachments`)
        .then((response) => response.data),
  };
}

export function examinationAttachmentDownload(
  examinationId: number,
  attachmentId: number,
) {
  return practitionerHttpService
    .get(
      `/api/internal/examinations/${examinationId}/attachments/${attachmentId}/download`,
      {
        responseType: "blob",
      },
    )
    .then((response) => {
      saveAs(
        new Blob([response.data]),
        contentDisposition.parse(response.headers["content-disposition"])
          .parameters.filename,
      );
    });
}

export function examinationStudyConsentsGet(examinationId: number) {
  return {
    queryKey: ["examinations", examinationId, "study-consents"],
    queryFn: () =>
      practitionerHttpService
        .get<
          StudyConsent[]
        >(`/api/internal/examinations/${examinationId}/study-consents`)
        .then((response) => response.data),
  };
}

export function studiesEligibleGet(examinationId: number) {
  return {
    queryKey: ["examinations", examinationId, "study-consents", "eligible"],
    queryFn: () =>
      practitionerHttpService
        .get(
          `/api/internal/examinations/${examinationId}/study-consents/eligible`,
        )
        .then((response) => response.data),
  };
}

export function studyRequestConsent(): UseMutationOptions<
  any,
  any,
  {
    examinationId: number;
    phoneNumber: string;
  },
  unknown
> {
  return {
    mutationFn: ({ examinationId, phoneNumber }) =>
      practitionerHttpService
        .post(
          `/api/internal/examinations/${examinationId}/study-consents/request`,
          { phone_number: phoneNumber },
        )
        .then((response) => response.data),
    onSuccess() {
      notify({
        text: lang.get("general.notificationSaved"),
        type: "success",
        duration: 1000,
      });
    },
    onError: userErrorMessage,
    onSettled(data, error, { examinationId }) {
      void queryClient.invalidateQueries([
        "examinations",
        examinationId,
        "study-consents",
      ]);
    },
  };
}
