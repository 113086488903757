import { useMutation } from "@tanstack/vue-query";
import { computed } from "vue";
import { adminUserSyncUcr, adminUserSyncUcrDryrun } from "../core/admin";

export function useAdminUserUcrSync() {
  const mutation = useMutation(computed(() => adminUserSyncUcr()));

  return mutation;
}

export function useAdminUserUcrSyncDryrun() {
  const mutation = useMutation(computed(() => adminUserSyncUcrDryrun()));

  return mutation;
}
