<template>
  <div class="panel panel-default panel-fill panel-actions">
    <div class="panel-heading panel-heading-underline">
      <h3 class="panel-title">
        {{ trans(title) }}

        <span v-if="totalComments">({{ totalComments }})</span>

        <button
          v-if="newDocumentationButton"
          type="button"
          class="btn btn-custom float-right documentation-actions-button"
          @click="newDocumentationButton.onAddNewDocumentation"
        >
          {{ trans("examination.addNewDocumentationAnswerButton") }}
        </button>

        <button
          v-if="dataHasEditMode"
          type="button"
          class="btn btn-custom float-right"
          @click="$emit('activate-edit-mode')"
        >
          {{ lang.get("general.edit") }}
        </button>

        <action-handler
          v-if="actionsMenu"
          class="left"
          :examination-actions="actionsMenu.examinationActions"
          :product="actionsMenu.product"
          :loading="actionsMenu.loading"
          :error-updating-actions="actionsMenu.errorUpdatingActions"
          :case-closed-at="actionsMenu.caseClosedAt"
          :view-option="actionsMenu.viewOption"
          @on-action="actionsMenu.onAction"
        />

        <!-- need a custom actions handler to be able to show only specific / some product actions -->
        <custom-action-handler
          v-if="customActionsMenu"
          :actions="customActionsMenu.actions"
          :loading="customActionsMenu.loading"
          @on-action="customActionsMenu.onAction"
        />

        <info-popup v-if="infoPopupText" :text-content="infoPopupText" />
        <span v-if="updatedDate" class="mx-2 field-timestamp">{{
          updatedDate
        }}</span>
      </h3>
    </div>
    <slot />
  </div>
</template>
<script setup>
import { lang } from "../../../../../i18n";
</script>
<script>
import InfoPopup from "../../../../Utils/InfoPopup.vue";
import ActionHandler from "./ActionHandler.vue";
import CustomActionHandler from "./CustomActionHandler.vue";

export default {
  components: {
    InfoPopup,
    ActionHandler,
    CustomActionHandler,
  },
  props: {
    title: String,
    updatedDate: String,
    infoPopupText: String,
    dataHasEditMode: Boolean,
    actionsMenu: Object,
    customActionsMenu: Object,
    newDocumentationButton: Object,
    totalComments: Number,
  },
};
</script>

<style lang="scss" scoped>
.field-timestamp {
  font-weight: normal;
  font-size: 14px;
  float: right;
}

.documentation-actions-button {
  margin-left: 10px !important;
}
</style>
