<template>
  <div id="system" class="tab-pane active">
    <clip-loader v-if="loading" />

    <div id="custom-search-input" class="mb-3">
      <form class="input-group col-md-12" @submit.prevent="search">
        <input
          id="examinationTransferSearchInputCaseNo"
          type="text"
          class="form-control input-lg"
          :placeholder="trans('forms.searchForCaseNumber')"
          :title="trans('general.search')"
        />
        <input
          id="examinationTransferSearchInputType"
          type="text"
          class="form-control input-lg"
          :placeholder="trans('forms.type')"
          :title="trans('general.search')"
        />
        <span class="input-group-btn">
          <button
            v-if="isSearching"
            class="btn"
            type="button"
            @click.stop="clearSearch"
          >
            <i class="fal fa-times" />
          </button>
          <button class="btn" type="button" @click.stop="search">
            <i class="far fa-search" />
          </button>
        </span>
      </form>
    </div>

    <div class="table-responsive">
      <table class="table table-hover">
        <thead class="sortable-table-header">
          <tr>
            <th>Examination</th>
            <th>Centre</th>
            <th>Type</th>
            <th>First attempt</th>
            <th>Latest attempt</th>
            <th>Retry</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(transfer, index) in system.transfers"
            :key="index"
            :class="getRowClass(transfer)"
          >
            <td>{{ transfer.examination }}</td>
            <td>{{ transfer.center }}</td>
            <td>{{ transfer.type }}</td>
            <td>{{ transfer.created_at }}</td>
            <td>{{ transfer.updated_at }}</td>
            <td>
              <button
                v-if="transfer.retryable"
                class="btn btn-primary"
                @click="retry(transfer.id)"
              >
                {{ trans("examination.retry") }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row-fluid float-right">
      <DerPaginate
        v-model="system.paginate.current_page"
        :page-count="system.paginate.last_page"
        @change="getTransfers"
      />
    </div>
  </div>
</template>

<script>
import DerPaginate from "../../../design-system/DerPaginate.vue";
import { practitionerHttpService } from "../../../app/practitioner-portal/store/practitioner-http-service";
import ClipLoader from "../../Utils/ClipLoader.vue";

export default {
  components: { ClipLoader, DerPaginate },
  data() {
    return {
      loading: false,
      system: {
        paginate: {
          current_page: 0,
          total: 0,
          last_page: 0,
        },
        transfers: [],
      },
    };
  },
  created: function () {
    this.getTransfers();
  },
  methods: {
    search() {
      this.isSearching = true;
      this.getTransfers();
    },
    getTransfers(page) {
      this.loading = true;
      if (typeof page === "undefined") {
        page = 1;
      }

      let caseNumber = $("#examinationTransferSearchInputCaseNo").val() ?? "";
      let type = $("#examinationTransferSearchInputType").val() ?? "";

      practitionerHttpService
        .get(
          "/api/internal/admin/logs/examination-transfers?page=" +
            page +
            "&case_number=" +
            caseNumber +
            "&type=" +
            type,
        )
        .then((data) => {
          var collection = data.data;
          this.system.paginate = collection.paginate;
          this.system.transfers = collection.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    clearSearch: function () {
      $("#examinationTransferSearchInputCaseNo").val("");
      $("#examinationTransferSearchInputType").val("");
      this.isSearching = false;
      this.getTransfers();
    },
    getRowClass(transfer) {
      if (transfer.status) {
        return "table-success";
      } else {
        return "table-danger";
      }
    },
    retry(transferId) {
      this.loading = true;
      practitionerHttpService
        .get(
          "/api/internal/admin/logs/examination-transfers-retry/" + transferId,
        )
        .then((data) => {
          this.getTransfers();
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.table-danger {
  background-color: #f8d7da;
}

.table-success {
  background-color: #d4edda;
}
</style>
