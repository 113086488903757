import { UseMutationOptions } from "@tanstack/vue-query";
import { practitionerHttpService } from "../../app/practitioner-portal/store/practitioner-http-service";
import { notify } from "@kyvg/vue3-notification";
import { userErrorMessage } from "../../mixins/UserErrorMessage.vue";
import { lang } from "../../i18n";

export function examinationImageRotate(): UseMutationOptions<
  any,
  any,
  {
    imageId: number;
    examinationId: number;
    rotation: number;
  },
  unknown
> {
  return {
    mutationFn: ({ imageId, examinationId, rotation }) =>
      practitionerHttpService
        .post(`/api/internal/${examinationId}/images/${imageId}/rotate`, {
          rotation: rotation,
        })
        .then((res) => res.data),
    onSuccess() {
      notify({
        text: lang.get("general.notificationSaved"),
        type: "success",
        duration: 1000,
      });
    },
    onError: userErrorMessage,
  };
}
